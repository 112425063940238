import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Home from "./page/Home.js";
import Test from "./page/Test.js";
import Result from "./page/Result.js";
import "./style/global.css";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/test">
          <Test />
        </Route>
        <Route path="/result/:id">
          <div id="top-container" class="bg">
            <Result />
          </div>
        </Route>
        <Route path="/">
          <div id="top-container">
            <Home />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

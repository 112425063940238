import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
const Home = () => {
  const history = useHistory();
  const [lang, setLang] = useState("en");
  const [step, setStep] = useState("전");
  const [showTitle, setShowTitle] = useState(false);
  const langChange = useCallback((target) => {
    setLang(target);
    setShowTitle(true);
    localStorage.setItem("cooldoc_lang", target);
  });

  useEffect(() => {
    const localLang = localStorage.getItem("cooldoc_lang");
    const selectedLang = localLang === "ko" ? "ko" : "en";
    setLang(selectedLang);
  }, [lang]);

  const goTest = () => {
    history.push("/test");
  };
  return (
    <div className="container intro">
      <section className="lang-container">
        <img src="/icon/globe.svg" alt="globe" />
        <button
          className={lang === "en" && "active"}
          onClick={() => {
            langChange("en");
          }}
        >
          En
        </button>
        <span>|</span>
        <button
          className={lang === "ko" && "active"}
          onClick={() => {
            langChange("ko");
          }}
        >
          Ko
        </button>
      </section>
      <img className="whoami" src="/image/main_title_who_am_i.png" />
      <img
        className={`sub-title ${showTitle ? "visible" : ""}`}
        src={`/image/main_title_${lang}.png`}
      />
      <div className="intro-text">
        {lang === "en" ? (
          <img src={`/image/영문-${step}.png`} />
        ) : (
          <img src={`/image/한글-${step}.png`} />
        )}
      </div>
      <button
        className="cta"
        onClick={() => {
          if (step === "전") {
            setStep("후");
          } else {
            goTest();
          }
        }}
      >
        {step === "전"
          ? lang === "en"
            ? "Click"
            : "클릭"
          : lang === "en"
          ? "Start"
          : "테스트하기"}
      </button>
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

const Result = () => {
  const { id } = useParams();
  const history = useHistory();
  const [lang, setLang] = useState("en");
  const resultObj = {
    enfp: {
      title: {
        en: "Campaigner",
        ko: "재기 발랄한 활동가",
      },
      feature: {
        en: `You look naive and simple but you have a lot of thoughts internally.\n\n Chances are you get energy and become active by meeting people. It must be vulnerable these days.Don't you feel funny to be home all day long? But when your friend, ENFP, said, "What up? come out! buddy" you run like an arrow and have fun. say, you're much of a social person. you're like a human being who feeds off people's responses. Don't you always hear that you are reagarded as a fun person and unique among people? you tend to be touched by the little things, so anyone would want to be a friend with you. \n\n You are good at compliments, so unlike other people's compliments, you have a talent for making others feel good by carrying all kinds of colorful rhetoric way to say. \n\n Like a cute puppy, you're rich in emotional expressions and expresses emotions well, so your social aspect stands out. you must have been a good social life!`,
        ko:
          "겉으로 보기엔 단순해 보이나 내적으로 생각이 아주 많은 인간이군! 사람을 만나면서 에너지를 얻고 활동적이게 되는 것 같은데.. 요즘 시국에 아주 힘들겠어. \n\n 집에만 있으면 기분이 가라앉고 그러진 않나? 하지만 때마침 친구가 근처에서 “ENFP 나와! 뭐해” 하면 쏜살같이 달려가서 재밌게 노는군. 아주 “인싸”야. 사람들의 호응과 반응을 먹고 사는 인간 같네만. 항상 사람들 사이에서 재밌는 사람으로 평가받고 특이하다는 소리도 많이 듣지 않나? 사소한 것에도 감동받아서 누구라도 친구가 하고 싶겠군. 칭찬도 곧잘 하는 편이라 다른 사람이 하는 칭찬과 다르게 온갖 화려한 미사여구를 붙혀서 남을 기분 좋게 하는 재주가 있군.\n\n  마치 귀여운 강아지같이 감정 표현도 풍부하고 감정을 잘 표출하는 편이라 사회적인 면모가 돋보이는군. 자네는 사회생활 잘하겠어!",
      },
      good: "isfp",
      bad: "isfj",
    },
    enfj: {
      title: {
        en: "Protagonist",
        ko: "정의로운 사회운동가",
      },
      feature: {
        en: `You like people, but it's a little different from other types. you have a soft spot for somebody so being warm to everyone stands out.\n\n You feel like a very conscientious and straightforward citizen, so I gravatate toward reflecting on yourself a lot. You tend to be very careful with what you say and do, and try very hard not to hurt the other person's heart. If you get hurt in a tense situation or desperate state, you often get hurter and hurter emotionally due to deepening of your mind.\n\n  You prefer to get feedback on your actions your approach,and your emotions sincerely! Kindness is basically based on the base, so you want your concern or interest to help others be happy! `,
        ko:
          "사람을 좋아하지만 다른 유형과는 좀 다른 느낌이네만! 여린 마음을 가지고 있어 모두에게 따듯하게 대하는 인간적인 면모가 돋보이는군.\n\n  매우 양심적인 모범시민 같은 느낌이라 자신에 대해 자아성찰을 많이 하는 편이네. 말과 행동을 매우 조심하는 편이며 상대방의 마음이 다치지 않게 매우 노력하는 편이군. 긴장되는 상황이나 절체절명의 상태에서 마음이 다치게 된다면 상처 받고 마음의 골이 깊어져 감정적으로 다치는 경우가 많네. 당신의 행동에 피드백이 오고 감정에 진심으로 다가가는 것을 선호하는군! \n\n 남들에게 기본적으로 다정함이 베이스로 깔려있는 편이라 당신의 관심이 남에게 도움이 되는 것을 나의 일처럼 기뻐해주는 인간군상이군!",
      },
      good: "enfp",
      bad: "infj",
    },
    entp: {
      title: {
        en: "Debater",
        ko: "뜨거운 논쟁의 변론가",
      },
      feature: {
        en: `You're living by thinking "I'm Main character of the world, giving off such a positive vive without worrying about others".\n\n Even if someone speaks ill of you, you gonna send them a famous line, like "mind your own business", "look who's talking". when you say something about what you know well, you may tell everything you know for the people right off the bat but otherwise you don't say exactly what you don't know. that point's straightforward and attractive to others. \n\n You don't compare yourself with others. You think "I'm what I am" and "you are what you are." self-esteem directly related to confidence, you knows your value better than anyone else and your way of thinking is straight, so you don't seem to be affected by others. You're not interested in others, but you love others to raise you up for what you're good at.\n\n  Basically you're such a neat freak! It's a group of humans who will be lucky to survive forever!`,
        ko:
          "남들 눈치도 안보고 무한한 긍정 에너지를 발산하며 이 세상의 주인공은 나야! 를 외치면서 사는군?\n\n 남이 당신에 대해 험담하거나 낮추는 말을해도 명대사를 하나 날려줄 것만 같군 “너나 잘하세요” 천상천하 유아독존이라는 표현이 딱 어울리지. 잘하는 것에 대해서는 신이나서 이것 저것 다 알려줄 수 있지만 모르는 것은 딱잘라서 모른다고 하는 점이 정말 매력적인 포인트군.\n\n 잘하는거 하나 못하는거 두개 라고해도 내가 잘하는게 정말 세계 제일이지!라고 생각해서 신경 안쓰는 타입. 남과 자신을 잘 비교하지 않으며 남은 남이고 나는 나다 라는 주의가 강하지. 자존감이 자신감과 직결되기 때문에 자신의 가치를 누구보다 잘 알고 있고 사고방식이 올곧아서 남의 영향을 잘 안받는거 같아. 남에 대해 관심 없기도 하지만 오히려 남이 내가 잘난 부분은 치켜세워주고 올려쳐주길 원하네? 정말 특이한 인간이군. 평생 운 좋게 살아남을 인간군상이야!",
      },
      good: "estj",
      bad: "enfj",
    },
    entj: {
      title: {
        en: "Commander",
        ko: "대담한 통솔자",
      },
      feature: {
        en: `If you have a desired goal, You're like a bulldozer who goes straight by any means.\n\n  You have a full of ambition and "BOYS, GIRLS, BE AMBITIOUS" is expressed as a human being, it's just like you. you are much of a job-person, also one of your biggest pet peeve is putting off what you have to do because your job is the top-priority.\n\n  Oh I think someone appraciate your act as a leader.You're a human group that fits perfectly with the word leader. But communication with others is important, and it seems that you tend to ignore it because of the fact that you gonna regard between your goal and other's goal as different values. unlike other extroverts you don't seem to get energy from humans, which means they seems to like doing something outside.\n\n  It's right for you to get energy from the outside. you are Workaholic, ambitious and passionate about something desired, but I mean, I think you'll hear a lot of people say you're a competent person. You're a human group that looks like you'd see a lot of that when you look at others, too!`,
        ko:
          "당신은 원하는 목표가 있거나 계획이 잘 짜여져 있다면 수단과 방법을 가리지 않고 직진하는 불도저 같은 사람이군.\n\n 마치 폭주 기관차 같달까? 야망도 가득하고 “BOYS, GIRLS, BE AMBITIOUS”라는 말이 인간으로 표현되면 바로 당신같군. 일머리도 좋지만 일이 1순위라서 해야할일을 미루는 것을 못참는 것으로 보아 정말 칼같은 성격이군. 오! 어딜가서 리더를 하면 정말 좋은 평가를 받곤 했지? 정말 리더라는 말이 딱 어울리는 인간 군상이네.\n\n 하지만 남들과 소통은 중요해, 가치관이 다르다고 무시하는 경향이 있는 것 같네만. 외부에서 에너지를 얻는 것은 맞지만 다른 외향형 들과 다르게 사람에게서 에너지를 얻는 것이 아닌 외부에서 무언가를 하는 것을 좋아하는 것 같네만. 워커홀릭에다 학업, 공부에 대한 야망이 많고 열정이 심해 능력자라는 말도 많이 들을 것 같군. 타인을 볼때도 그런 부분을 많이 볼 것 같은 인간 군상이군!",
      },
      good: "estp",
      bad: "istp",
    },
    esfp: {
      title: {
        en: "Entertainer",
        ko: "자유로운 영혼의 연예인",
      },
      feature: {
        en: `You're so rolling up your sleeves that your energy can be run out of. How could you be so energetic? what I am basically saying is that you are social person. \n\n You are passionate and have momentum. I think you can be a celebrity no matter you go. and also you enjoy getting attention, so you do not feel pressured and make your mood blend well with people.but When you are together, introverts will feel a little tired.\n\n   I mean, you're like a dog that likes people so much,which means to fit you to make the term sociable every well. If you have a hard time even if you have to fall into depression, you'll cheer you tend to meet people with that mindset and overcome it. You don't think deeply aboyt what you can't do.\n\n  And you say like "I can cross that bridge when I come to it" You're a perfect human group to make friends with. Why don't you be friends with me?`,
        ko:
          "정말 당신은 어딜가도 사람들을 구워삶을 수 있을 것 같군! 어떻게 이렇게 활발할까? “인싸”라는 말이 아주 잘 어울리는 인간이군.\n\n 열정도 있고 추진력도 상당해서 어딜가도 사람들의 연예인이 될 수 있을 것 같네. 주목받는걸 즐기기도 해서 오히려 부담스럽지도 않고 자신의 분위기를 사람들과 잘 어우러지게 하네. 같이 있으면 내향형 인간들은 살짝 기빨리는 느낌을 받을 수 있을 것 같군. 사람들을 너무 좋아하는 강아지 같아서 사교적이라는 말이 매우 잘어울리는군.\n\n 하고싶지 않은 생각은 깊게 하는 편이 아니라 우울감에 빠질 일이 있어도 힘들면, 힘내야지! 라는 마인드로 사람들을 만나 극복하는 편이네. 정말 친구 삼기 딱 좋은 인간 군상이군, 자네 나랑 친구해보지 않겠나? ",
      },
      good: "intj",
      bad: "istj",
    },
    esfj: {
      title: {
        en: "Consul",
        ko: "사교적인 외교관",
      },
      feature: {
        en: `A human body at the center of a human being! When you go to a party, you'll do a good job of hosting. No, what about the CEO? I think you'll do well.\n\n  I think people around me will follow you very much because I don't stick to my opinion and collect a lot of other people's opinions and compromise. There's a reason why you're a social diplomat.\n\n  He's a bit emotional, so he's very humane when it comes to dealing with things. Realistic and emotional, I think I can be an inspiration to every young man living in this era!\n\n First place in social life, first place in relationship, what are you missing? You've got people standing in line around you to help you get out of trouble.\n\n  How can I not like you when you treat me humanely instead of justifying me!`,
        ko:
          "사람의 중심에 있는 인간군상이군! 자네는 파티에 가면 사회자를 맡아도 능수능란하게 잘 해내겠어.\n\n 아니, 더 나아가서 CEO는 어떤가? 잘 해낼 것 같은데. 본인 의견이 있어서 그것만을 고집하지 않고 다른 사람의 의견을 많이 수렴해서 타협하는 편이라 주변 사람들이 당신을 매우 따를 것 같군.\n\n 사교적인 외교관이라는 말이 괜히 있는게 아니야. 약간 감성적인 편이라 일처리를 하는데 있어서 인간적인 면모가 많이 돋보이는 군. 현실적인데 감성적이라 이시대를 살아가는 모든 청년들에게 귀감이 될 수 있을 것 같군! 사회생활을 하는데에 있어서도 1등, 연애도 1등, 당신 대체 빠지는게 무엇인가? 당신은 힘들어도 주변에서 발벗도 나서줄 사람들이 줄을 서있군. 인맥관리를 명분에서만 그치지 않고 인간적으로 대해주니 어떻게 당신을 좋아하지 않을 수 있겠는가!",
      },
      good: "infj",
      bad: "intp",
    },
    estp: {
      title: {
        en: "Entrepreneur",
        ko: "모험을 즐기는 사업가",
      },
      feature: {
        en: `At first, you looks hard and thorough, but as we get to know about you, we'll find you attractive.\n\n  We will find you, you showing an generous aspect and warm-hearted person to the people who think you're close to. You often want power and are ambitious, so you take good care of your own interests and take practical interests faster than anyone else! you are very driven.\n\n You take action before saying. "Let's just do it." you don't like long introductions, procrastination so your bosses will like it a lot by taking care of it quickly. However, there are many times when you express your two faces case by case. So you act like a masking in the man and hiding your inner thoughts.\n\n  But there's no offense, and you're always acting honestly for "practical benefit", so you can't not have been the man who people don't like.`,
        ko:
          "처음에는 딱딱해 보이고 철두철미해보이지만 알아갈수록 굉장히 매력적인 인간이군.\n\n 자신이 친한 사람이라고 생각하는 인물들에게 한없이 너그러운 면모를 보여줘 인간적인 면모가 돋보이네. 권력을 가지고 싶어하고 야망이 있는 경우가 많아 자신의 이익을 잘 챙기고 실리를 누구보다 빠르게 챙기는군! 추진력도 강해 말보다는 행동이 앞서 “일단 하자”라는 주의군. 앞뒤 안가리고 서론이 긴걸 싫어해서 신속하게 일처리해서 상사들이 매우 좋아하겠어!\n\n 하지만 두 얼굴을 표현할 때가 많아서 자신의 속내를 숨기고 행동하는 여우같은 면모도 많군.\n\n 하지만 악의는 없고 항상 “실리”를 위해서, 솔직하게 행동하는 것 뿐이니 미워할 수 없는 인간군상이군!",
      },
      good: "entj",
      bad: "enfp",
    },
    estj: {
      title: {
        en: "Executive",
        ko: "엄격한 관리자",
      },
      feature: {
        en: `Practically you're already born people-person. Chances are If you go to a party,you gonna do well even if you're in charge of the host.\n\n  Furthermore, what is CEO like? I think you'll do well. People around you will follow you very much because you don't insist on making a decision to other people and tend to accommodate people by collecting opinions from others.you are a little emotional, so standing out a lot in your human aspect. realistic and emotional, so I think it can serve as a role for all young people living in this era! You're number one in social life, number one in relationships, and what the hell are you missing? Even if you have a vulnerable spot.\n\n  You have a line of people around you who can help you down the road. Who wouldn't want to be good at treating person humanly like you?`,
        ko:
          "당신 센스가 상당하군, 그 기운이 여기까지 느껴져.\n\n 자기관리도 철저하고 일이던 연애던 아주 완벽주의자야! 사람이 이렇게 완벽할 수가 있나? 할 정도로 매끈한 유리병 같군. 계획을 짜고 실행하는 추진력도 상당해서 하루하루 멋진 계획을 세우고 이루어내지. 인생의 큰 그림을 그리고 착실히 실행해가는 당신, 너무 멋진 인간 군상이군.\n\n 현실적으로 최대로 열심히 살아가기 때문에 사회생활에서도 똑부러진다는 평가를 많이 받을 것 같네만. 하지만 감성적인 말로 위로해주는건 좀 무리가 있어서 사회생활이나 연애에 있어서 현실적이라는 말을 많이 듣곤 했지? 의리도 넘치게 많아서 주변에 친구들이 끊이질 않을 것으로 보이네.\n\n 친구에서 끝나는게 아니라 챙기는 것도 완벽하게 챙겨줘서 누구나 당신을 따르고 좋아할 것 같은 인간군상이군!",
      },
      good: "infp",
      bad: "esfp",
    },
    isfp: {
      title: {
        en: "Adventurer",
        ko: "호기심 많은 예술가",
      },
      feature: {
        en: `You are an introvert, but it's easy to feel that you can do anything if you have confidence! Without considering your allocated energy, sometime you open your plate easily.\n\n  That is a vulnerable spot right now. Once you're hooked on something, you'll rush into it, so you're very driven. Chances are you are so sensitive to outside appointments that you could lose your energy at home just thinking about the appointment but don't you like to hang out with friends you like? Even though you get tired quickly, you seem to play better than anyone else once somebody set you the stage. It's getting on your nerve to find you, but don't you hate being forgotten? There are so many aspects that stand out.\n\n  When you have something to do, you try to take care of it faster than anyone else, but you give up what you think you can't do faster than anyone else. You are a human group of fitting you in "i am what I am"`,
        ko:
          "내향적인 인간이지만 또 자신감이 생기면 뭐든 할 수 있다는 마음이 쉽게 드는군!\n\n 평소 밖에서 에너지를 얻는편이 아님에도 자신의 할당 에너지를 고려하지 않고 판을 벌리는 경우가 있어서 경계해야할 부분이야.\n\n 무언가에 한번 꽂히면 물불 안가리도 달려들기 때문에 추진력도 상당하군. 바깥 약속에 굉장히 예민해서 약속 생각만 해도 집 안에서 기력이 빠져나가는 것 같은데.. 자네 혹시 좋아하는 친구들이랑 있을 때는 잘 놀지 않는가?\n\n 금방 지치긴 해도 판깔아주면 누구보다 잘놀 것같은 사람이네만. 당신을 찾아주는건 귀찮지만 또 잊혀지는건 싫지 않나? 정말 여러가지 면모가 돋보이는군. 할 일이 생겼을땐 누구보다 빨리 처리하려고 하면서 막상 못할 것 같은 일은 칼같이 포기하는군.\n\n 너는너, 나는나! 라는 말이 가장 어울리는 인간군상이군!",
      },
      good: "istp",
      bad: "estj",
    },
    isfj: {
      title: {
        en: "Defender",
        ko: "용감한 수호자",
      },
      feature: {
        en: `You seem to be doing your best on the table, but are you specialized in handling certain task?\n\n  If I were your boss, I would definitely call you the number one employee! It's cool and reliable to find your own way and do it silently. If you have a problem with someone, you don't respond or get out of it easily.However, you have right up you alley, what is to say,easily forgiving others for appealing to their emotions. If the environment is well-justified, it seems that you will have a smooth social life in handling everything.\n\n  Being Self-objectification is better than anyone else, so you look at others a lot and are conscious, but it can be said that you are not stressed out by this, but you naturally scan others. You don't give advice to people you're close to, but you're also very generous in giving advice to people you think you're really close to.\n\n  That stands out! It is a group of humans who form and establish values in your own time and live hard with your own power rather than the energy obtained from the others and outside.`,
        ko:
          "주어진 일에 최선을 다해서 꼼꼼하게 처리하는 것 같은데, 자네 혹시 절차가 확실한 일을 처리하는데에 특화되어있는가?\n\n 내가 당신 상사라면 꼭 1등 직원이라고 불러주고 싶군! 자신만의 방법을 찾아서 묵묵히 해내는게 멋있고 신뢰가 가네. 만약 누군가와의 관계에서 문제가 생겨도 요리조리 잘 빠져나가거나 대꾸를 안해서 답답한 면도 있지만.. 하지만 남이 감정에 호소한다고 해서 쉽게 용서해주는 면도 있어 인간적인 면이 있는 사람이군.\n\n 당위성이 잘만 주어진 환경이라면 모든 일처리를 하는데 있어서 원활한 사회생활을 할 것으로 보이네. 자기 객관화가 누구보다 잘되어있어서 남 눈치도 많이보고 의식도 하지만 이로 인해 스트레스를 받는게 아니라 자연스럽게 남들을 스캔한다고 할수 있지. 가까운사람에게 조언을 잘 하지는 않지만 정말 친하다고 생각되는 사람에겐 아낌 없이 조언을 해주는 면모도 정말 돋보이네! 혼자만의 시간에서 가치관을 형성하고 정립해서 밖에서 얻는 에너지보다 혼자만의 동력으로 열심히 살아가는 인간군상이군. ",
      },
      good: "intp",
      bad: "entp",
    },
    istp: {
      title: {
        en: "Virtuoso",
        ko: "만능 재주꾼",
      },
      feature: {
        en: `I don't know what you're thinking sometimes because you're good at poker and don't have mood swings.\n\n  Just like the waves in the sea, the trees shake in the mountains, the mountains are mountains, the water is water is the best match for the human form! But You take your own interests thoroughly, so you won't go anywhere to lose anywhere. You're a perfect man if you support him! You like to be recognized by someone you recognize more than anyone else. It looks blunt on the outside, but on the inside? It's just as hard.\n\n  I think it's good that you're consistent on the outside and inside. If you ask me to do everything, I'll say, "I'll do "What are you thinking about?" That's why you're so fast at your work. There's also a way to overcome things that other humans can't do because they're afraid of, with their own boldness. What a bold human form!`,
        ko:
          "포커페이스도 잘하고 감정기복이 없어서 때론 당신이 무슨 생각을 하는지 모르겠네만!\n\n 바다에 파도가 치듯 산에 나무가 흔들리듯, 산은 산이요 물은 물이로다 라는 말이 가장 잘 어울리는 인간군상이군! 하지만 본인 이익은 철저하게 챙기는편이라 어딜 가서 손해볼 일은 없겠어. 능력도 받쳐주면 아주 완벽한 사람이야! 자신이 인정하는 사람에게 인정받는 것을 그 누구보다 좋아하는군. 겉으로 볼땐 무뚝뚝한데 속은? 똑같이 딱딱하군. 외면이나 내면이나 한결같아서 좋다고 생각하네.\n\n 전반적으로 모든 일들을 시키면 “시키면 해야지.. 뭘 생각을해”라는 마인드로 진행해서 일처리속도도 곧잘 빠른 편이네. 다른 인간들이 무서워서 실행하지 못하는 것들을 특유의 담대함으로 이겨내는 면모도 있어 무척이나 대범한 인간군상이네!",
      },
      good: "enfj",
      bad: "intj",
    },
    istj: {
      title: {
        en: "Logistician",
        ko: "청렴결백한 논리주의자",
      },
      feature: {
        en: `You're not much of a people person. When asked if the house is the most comfortable, you are the first person to answer "YES" with confidence. \n\n chances are one of your biggest pet peeve is that you're never seen, don't know, and are not close to. I don't think anyone knows about you and your thoughts along with what's going on deep inside because you have a lot of thoughts. also you don't express your feelings very well. If you express your feelings, you might not be able to sleep all day long because you think you caused trouble to the other person.\n\n   you try to be a social being more than anyone else, but you don't sympathize and resonate well with the other person's work, so youtend to grasp the person's sectional situation. However, if you judge them to be a close person, you tend to roll up your sleeve and give all the information and knowledge you know. Even if you can't resonate emotionally to the extent you know.`,
        ko:
          "극도로 사람을 피하고 싶어하는군. 집이 제일 편하냐고 물어보면 자신있게 “YES”라고 가장 먼저 대답할 사람이 당신이네.\n\n 세상에서 가장 불편한 사람은 처음보고, 모르고, 안친한 사람이군. 생각이 많아 깊숙한 속마음에서는 무슨 일이 일어나고 있는지 아무도 모를 것 같군. 하지만 감정을 잘 표출하지 않는군.\n\n 혹여라도 감정을 표출하게 되면 상대방에게 폐를 끼쳤다는 생각에 하루종일 잠을 못이룰 지도? 사회적인 인간이 되도록 누구보다 노력하지만 상대방에 일에 잘 공감하지 못하는 편이라 감정을 같이 느껴주기 보다는 그사람의 단면적인 상황만을 파악하는편. 하지만 친한 사람이라고 판단되면 자신이 아는 선에서 감정적인 공감은 못해줘도 아는 정보들과 지식을 총 동원해서 도와주는 편이네. \n\n기브앤 테이크도 철저한 편이라 사소한 것을 선물받아도 바로 돌려주는 인간군상이군!",
      },
      good: "esfj",
      bad: "estp",
    },
    infp: {
      title: {
        en: "Mediator",
        ko: "열정적인 중재자",
      },
      feature: {
        en: `A vive and mood determine you. The more you make a go-to place and go to that place, the merrier you get! i think you would say that you are emotion person. Even when you do SNS, you don't post a picture in vain at all. I think you gonna have to pay attention to the filter and the temperature of the picture in order to express the true you. No matter what people do, you don't want to chase the trend.\n\n   I think you wanna get your own "identity" to stand out. You like be alone, so you tend to gravitate toward decorating you own place,such as decorating your room and orchestrating your cell phone,also you are very excited to meet people with a common taste. if you suddenly feel lonely, getting a space filled with people you like and food you like gives you strength and endorphins.\n\n  You know yourself better than anyone else, but you like others to analyze about you and explore.If someone analyzes the character with interest, one can definitely be a soulmate down the road.`,
        ko:
          "분위기가 나를 좌우한다. 분위기를 내는 것 분위기 좋은 곳에 가는 것도 좋아하는 당신. 당신을 표현한다면 “감생감사” 감성에 살고 감성에 죽는다고 할 수 있지.\n\n  sns를 할때에도 사진 하나 허투로 올리지 않는군. 필터랑 사진 온도까지 신경써줘야지 진정한 당신을 표현할 수 있는 것 같네 뭘 하더라도 유행은 쫓고 싶지 않지만 나만의 “아이덴티티”가 돋보이는군.  혼자 있는 것도 좋아해서 방꾸미기, 핸드폰 꾸미기 등 자신이가 있는 공간을 예쁘게 꾸미고 싶기도 하지만 사람들이랑 공통된 취향을 가지고 만나는 것도 매우 신나하는군.\n\n 문득 외로워질때면 좋아하는 사람들과 좋아하는 음식 좋아하는 것들을 가득 채운 공간에 있으면 힘이나고 엔돌핀이 솟구치는 것으로 보이네! 누구보다 자신에 대해 잘 알지만 남들이 나에대해 분석하고 탐구해주는걸 좋아하는군. 누군가 관심을 가지고 캐릭터를 분석해준다면 분명 인생의 소울메이트가 될 수 있을 거야. ",
      },
      good: "istj",
      bad: "esfj",
    },
    infj: {
      title: {
        en: "Advocate",
        ko: "선의의 옹호자",
      },
      feature: {
        en: `You know so well that reality and ideals are different that you can be pessimistic sometimes. Do you often feel depressed by humans? However, you always give hope to humans, so you have a human side. Also dealing with people is for you.that is right up your alley. And practically, you're a warm-hearted person.\n\n  You also have a sense of humor, so unlike popular humor, you are good at making skeptical jokes to people, so you seem to be subtly popular among people. You have a lot of attachment, so you can't break off relationships with people, but if you don't, you tend to turn cold-hearted. You have your own personal bubble and your own territory so if there is anyone who wants to trespass this, you can never make them come in.\n\n  You like to find out people and understand their characteristics, so you tend to analyze them thoroughly before meeting and knowing.you're so firm in your beliefs that you're trying hard not to break down your established values. It's cool.`,
        ko:
          "현실과 이상이 다르다는걸 너무 잘 알고 있어서 이따금 염세적일 수 있는 인간이군. 자네 인간에게 환멸을 느끼는 순간이 잦은가?\n\n 하지만 항상 인간에게 희망의 끈을 놓지 않는 것으로 보아 인간적인 면모가 있군. 사람을 잘 다루는 재주가 있어. 또 근본적으로는 따듯한 사람이라서 아주 차가운 편도 아니네! 유머감각도 있어서 유행하는 유머와는 다르게 사람들에게 회의적인 개그도 곧잘 쳐서 은은하게 사람들 사이에서 인기가 많을 것 같군. 정이 많아서 사람과의 관계를 잘 끊진 못하지만 아니다 싶으면 매정하게 돌아서는 면모도 있군. 본인만의 선과 바운더리가 확고해서 이걸 깨려는 사람이 있으면 절대 못들어오도록 철저한 면도 돋보이네만! 사람을 관찰하고 특성을 파악하는 것을 즐겨해서 사람을 사귀기 전에 철두철미하게 분석하는 편이군. 자신의 신념도 확고해서 이미 정립된 가치관을 무너트리지 않으려고 부던히 노력하는 모습이 멋지군!",
      },
      good: "esfp",
      bad: "entj",
    },
    intp: {
      title: {
        en: "Logician",
        ko: "논리적인 사색가",
      },
      feature: {
        en:
          "If you instantly fall in love with something, you are obsessed with that.\n\n  Regardless of studying,excercising etc. sometimes you are lazy to do everything, but you Gravatate toward investing both time and money when you love something. You are brilliant and also do something fast to work, so I think you'll get good results even if you don't try hard.\n\n  With all due respect, you don't like people? I think you'll hear that you are pessimistic often. like a cat. You're more of a cat-person than people-person and You're not good at expressing your emotions, so you get really nervous when dealing with people. You also express an affection uniquely, so I think there are many cases where the other person doesn't notice. You have a unique way of dealing with people.",
        ko:
          "학업이나 지식에 대한 열망이 뛰어나고 하나에 푹 빠지면 못헤어나오는 당신은 정말 매력적인 인간이군.\n\n 모든 일을 귀찮아 하지만 자신이 빠진 거에는 시간과 돈 모두 투자하는 경향이 있네. 일처리 속도가 빠른 것도 빠른 건데 굉장히 똑똑해서 노력을 많이 하지 않아도 결과가 매우 좋게나오는 경우가 많을 것 같네!\n\n 염세적인 편이라는 말도 자주 들을 것 같네만 혹시 인간을 별로 안좋아하는가? 인간보다는 고양이를 더 좋아할 것 같은 느낌이군. 혹시 고양이 같은 인간을 좋아하면 푹 빠질 자신이 있는가? 감정 표현을 곧잘 하지는 않아서 사람을 대할때 굉장히 긴장하는 경우가 있군.\n\n 애정표현도 독특하게 하는 편이라 상대방이 알아채지 못하는 경우도 많을 것 같네만. 사람을 대하는 방식이 누구보다 특이한 인간군상이군!",
      },
      good: "entp",
      bad: "infp",
    },
    intj: {
      title: {
        en: "Architect",
        ko: "용의주도한 전략가",
      },
      feature: {
        en: `Have you heard that you're cold-hearted? But when you hear such a thing, I think you would argue, "I'm soft inside, but I'm emotional."\n\n  When you meet various human groups, your personality changes slightly depending on who the other person is.\n\n There're pros and cons. You have a strong individualistic aspect, so you understands what others haven't noticed, but you don't seem to tell anybody. If you don't wirk and think that this is right while talking, you talk right of the bat, so you're the type to talk directly regardless of whether you meet someone or a intimate friend in everywhere.\n\n  However, considering that you are reflecting on yourself again, thinking that you should love people, you are quite human.\n\n  You don't care what people think, don't seem to mind if others notice your thought. You have a lot of thoughts, so 24 hours isn't enough to think about. \n\n As you know you have so many many questions that you are full of curiosity.`,
        ko:
          "당신 혹시 차갑다는 말을 많이 듣나? 하지만 그런 말을 들으면 속으로는 나는 여린데, 감성적인데? 라고 반박하곤 할 것 같네.\n\n 다양한 인간 군상을 만나면 상대방이 누구냐에 따라 성격이 약간씩 바뀌는 면모가 있군. 어떻게 행동해야할지 몰라서 그러는 것 같네만? 개인주의적인 면모가 강해 남들이 눈치채지 못한 것을 잘 파악하지만 굳이 말해주지 않는 것 같네. 대화하다가 이게 아니다 싶으면 바로바로 말하는 면이 있어서 사회생활을 하다 만난 사람이건 친구건 가리지 않고 직접적으로 말하는 타입이군. \n\n하지만 그러다가도 사람을 사랑해봐야지 하며 다시 자아성찰을 하는 면도 있는 것으로 보아 꽤나 인간적인 면모가 있군. \n\n자신이 똑똑한 편이라고 생각하며 남들이 이런 생각을 알아채더라도 개의치 않는 것 같네. 생각이 아주 많은 편이라 생각만 하기에도 24시간이 모자라는군. \n\n궁금한게 너무 많아서 호기심이 가득한 인간군상이네!",
      },
      good: "isfj",
      bad: "isfp",
    },
  };

  const [result, setResult] = useState({
    title: { ko: "", en: "" },
    feature: { ko: "", en: "" },
    good: "",
    bad: "",
  });

  useEffect(() => {
    if (!id || resultObj[id] === undefined) {
      goHome();
    }
    setResult(resultObj[id]);
  }, []);

  useEffect(() => {
    const localLang = localStorage.getItem("cooldoc_lang");
    const selectedLang = localLang === "ko" ? "ko" : "en";
    setLang(selectedLang);
  }, [lang]);

  const goHome = () => {
    history.push("/");
  };

  return (
    <>
      {result.good && (
        <div className="container result">
          <img className="logo" src="/image/logo.png" alt="logo" />
          <section className="result-container">
            <div className="title-container">
              <img
                src="/image/doc_pointing.png"
                alt="cooldoc pointing downside"
                className="point-down"
              />
              <h1 className="result-header">
                <div className="neon">{id}</div>
                <div className="sub-text">{result.title[lang]}</div>
              </h1>
            </div>
            <div className="main-box">{result.feature[lang]}</div>
          </section>
          <section className="partner-container">
            <div className="partner-box">
              <h2>{lang === "en" ? "Best match" : "최고의 궁합"}</h2>
              <h3 className="partner-type">{result.good}</h3>
            </div>
            <div className="partner-box">
              <h2>{lang === "en" ? "Worst match" : "최악의 궁합"}</h2>
              <h3 className="partner-type">{result.bad}</h3>
            </div>
          </section>
          <section className="share">
            <a class="icon" target="_blank" href="http://pf.kakao.com/_xaCudK">
              <img src="/icon/kakao.svg" alt="카카오톡" />
            </a>
            <a
              class="icon"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100028190972613"
            >
              <img src="/icon/facebook.svg" alt="페이스북" />
            </a>
            <a
              class="icon"
              target="_blank"
              href={`https://www.youtube.com/channel/UCBlFQn7w0i4hPTWJXHZJQvw`}
            >
              <img src="/icon/youtube.png" alt="유튜브" />
            </a>
            <a
              class="icon"
              target="_blank"
              href="https://www.instagram.com/cooldocsound"
            >
              <img src="/icon/instagram.png" alt="인스타그램" />
            </a>
            <a
              class="icon"
              target="_blank"
              href="https://www.tiktok.com/@dr.djay"
            >
              <img src="/icon/tiktok.png" alt="틱톡" />
            </a>
          </section>
          <button className="cta" onClick={goHome}>
            {lang === "en" ? "Retry" : "다시하기"}
          </button>
        </div>
      )}
    </>
  );
};

export default Result;

import React, { useState, useCallback, useEffect } from "react";
import { firestore } from "../firebase";
import { useHistory } from "react-router-dom";

const Progress = ({ index, maxIndex }) => {
  return (
    <div className="progress-container">
      <div
        className="indicator"
        style={{ transform: `translateX(${index * (320 / maxIndex)}px)` }}
      ></div>
    </div>
  );
};

const quiz = [
  {
    q: {
      en:
        "Strange place, this is not your planet? What's the first thing you're likely to take?",
      ko:
        "낯선 곳, 이곳은 당신의 행성이 아니다? 당신이 가장 먼저 취할 것 같은 행동은?",
    },
    a: [
      {
        txt: {
          en: "Slowly grasp the planet, organize your thoughts, and act.",
          ko: "이 행성에 대해 천천히 파악하고 생각을 정리한 후 행동한다.",
        },
        type: "j",
      },
      {
        txt: {
          en: "Let's go! I'm sure you'll think of anything when you walk.",
          ko:
            "일단 가자! 걷다보면 무슨 생각이건 나겠지 하는 마음으로 걸음을 재촉한다.",
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `“This place, not the your planet. I think it's hard to breathe.” What's your choice?`,
      ko: `당신의 행성이 아닌 이곳, 숨쉬기가 약간 힘든 것 같은데 당신의 선택은?`,
    },
    a: [
      {
        txt: {
          en: `It was easy to breathe on my planet. Let's hurry up and go to a place where it's easy to breathe.`,
          ko: `우리 행성에서는 숨쉬기가 편했지. 얼른 숨쉬기 편한 장소로 가자.`,
        },
        type: "t",
      },
      {
        txt: {
          en: `Will I be comfortable if I get used to it? Can I live in a place where it's hard to breathe? What should I do?`,
          ko: `적응하면 편해질까? 숨쉬기가 힘든 이곳에서 내가 살 수 있을까? 어떡하지?`,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en:
        "As I walk, there is an unfamiliar animal. This animal is like a cat on Earth.",
      ko:
        "걷다보니 낯선 동물이 있다. 이 동물은 지구에 있는 고양이랑 비슷한데..?",
    },
    a: [
      {
        txt: {
          en: "It's so cute, so I'll touch it.",
          ko: "너무 귀여우니 일단 만져본다.",
        },
        type: "f",
      },
      {
        txt: {
          en: "It might bite me, so I look around and don't touch it.",
          ko: "날 물지도 모르니 일단 주위를 살펴보고 만져보지는 않는다.",
        },
        type: "t",
      },
    ],
  },
  {
    q: {
      en: `You are looking for a comfortable place and taking a step. What do you think?`,
      ko: `편안한 장소를 찾아서 발걸음을 옮기는 당신의 생각은?`,
    },
    a: [
      {
        txt: {
          en: `I need to find a way to overcome this situation. I can't just think about it like this.`,
          ko: `이 상황을 타개할 방안을 찾아야지. 이렇게 생각만 할 수는 없어.`,
        },
        type: "t",
      },
      {
        txt: {
          en: `It's so scary, and I can't wait to get out of this situation. I have to find someone similar to me..`,
          ko: `너무 무섭고, 이 상황 자체를 빨리 벗어나고 싶어. 나랑 비슷한 사람이라도 찾아야해.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en:
        "I think this animal has a master! You're talking to me in a friendly.",
      ko: "이 동물에 주인이 있는 것 같다! 나에게 반갑게 말을 거는데?",
    },
    a: [
      {
        txt: {
          en: `"Hello, are you feeding this animal? What's your name? You're so cute!"`,
          ko: `"안녕하세요, 이 동물은 키우시는 건가요? 이름은 뭔가요? 너무 귀여워요!"`,
        },
        type: "e",
      },
      {
        txt: {
          en: `"Hello." glances past after greeting.`,
          ko: `"안녕하세요." 인사 후 흘끔 보며 지나친다.`,
        },
        type: "i",
      },
    ],
  },
  {
    q: {
      en: "The scenery outside is an unusual color. What do you think?",
      ko: "밖으로 보이는 풍경이 예사롭지 않은 색감이다. 당신이 드는 생각은?",
    },
    a: [
      {
        txt: {
          en:
            "Wow, what kind of world is going to open up if we go out there? Is it different from Earth? If I go over there, will it fall?",
          ko:
            "와 저기로 나가면 무슨 세계가 펼쳐질까? 지구와 다를까? 내가 저기에 가면 떨어질까?",
        },
        type: "n",
      },
      {
        txt: {
          en:
            "From the color of the rainbow, it seems to be a similar environment to Earth.",
          ko:
            "색을 보아하니 무지개같은 것으로 보아 지구와 비슷한 환경인 것 같군.",
        },
        type: "s",
      },
    ],
  },
  {
    q: {
      en: "You can see a space to rest. What are you gonna do?",
      ko: "쉴 수 있는 공간이 보인다. 당신이 취할 행동은?",
    },
    a: [
      {
        txt: {
          en: "Let's lie down. It was a hard day.",
          ko: "일단 눕자. 너무 고된 하루였다.",
        },
        type: "f",
      },
      {
        txt: {
          en: "I think we should look at more creatures outside.",
          ko: "밖에서 있는 생물들을 더 관찰해봐야할 것 같다.",
        },
        type: "t",
      },
    ],
  },
  {
    q: {
      en:
        "The next day, I woke up and found strange creatures surrounding me. What's your first word?",
      ko:
        "다음날, 일어나보니 낯선 생물들이 나를 둘러싸고 있다. 당신의 첫마디는?",
    },
    a: [
      {
        txt: {
          en: `"Who are you?"`,
          ko: `"누구세요?"`,
        },
        type: "i",
      },
      {
        txt: {
          en: `"Hello, are you a resident of this place?"`,
          ko: `"안녕하세요 혹시 이곳 주민이신가요?"`,
        },
        type: "e",
      },
    ],
  },
  {
    q: {
      en: "You was handed food to do you a favor. What are you gonna do?",
      ko: "당신에게 호의를 베풀려고 음식을 건네받았다. 당신이 취할 행동은?",
    },
    a: [
      {
        txt: {
          en: `"It's a lot, let's eat together, where is your house?"`,
          ko: `"양이 좀 많은데 같이 먹어요, 당신 집은 어디예요?"`,
        },
        type: "f",
      },
      {
        txt: {
          en: `"I'll take it. I won't forget your kindness."`,
          ko: `"감사히 받겠습니다. 은혜는 잊지 않을게요."`,
        },
        type: "t",
      },
    ],
  },
  {
    q: {
      en: "A strange spaceship came down to rescue you. What are you gonna do?",
      ko: "당신을 구조하려고 낯선 우주선이 내려왔다. 당신이 취할 행동은?",
    },
    a: [
      {
        txt: {
          en: `"Here, here! I need your help."`,
          ko: `"여기요 여기! 구조 부탁드립니다"`,
        },
        type: "p",
      },
      {
        txt: {
          en: `"I think you should come down and figure out what's going on, where is this place?"`,
          ko: `"일단 내려오셔서 상황을 파악하셔야 할 것 같습니다, 이곳은 어디인가요?"`,
        },
        type: "j",
      },
    ],
  },
  {
    q: {
      en: `You found an oxygen respirator. What should you do now?`,
      ko: `산소호흡기를 찾았다. 이제 어떻게 할까?`,
    },
    a: [
      {
        txt: {
          en: `Let's find food. I think smoke is rising over there.`,
          ko: `일단 식량을 구해보자 저쪽에 연기가 피어오르는 것 같은데?`,
        },
        type: "p",
      },
      {
        txt: {
          en: `Let's make a plan on how to get food and put it into action.`,
          ko: `식량을 어떻게 구할지 계획을 세워서 행동으로 옮기자.`,
        },
        type: "j",
      },
    ],
  },
  {
    q: {
      en: `You found suspicious alien creatures that distribute food. What should you do?`,
      ko: `식량을 나누어주는 수상한 외계 생명체들을 발견했다 어떻게 할까?`,
    },
    a: [
      {
        txt: {
          en: `Aliens are scary. I spin around and figure out what's going on.`,
          ko: `외부인들은 무서우니 빙 돌면서 상황을 파악한다`,
        },
        type: "t",
      },
      {
        txt: {
          en: `I can't stand being hungry. I'll go and talk to him.`,
          ko: `배고픈건 못참지, 일단 가서 말을 건다`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `It turns out to be a good alien creature. How should you express my gratitude?`,
      ko: `알고보니 선량한 외계 생명체인 것 같다. 고마움을 어떻게 표시할까?`,
    },
    a: [
      {
        txt: {
          en: `I give food to the earth and talk to them in a friendly way to identify the planet.`,
          ko: `친근하게 말을 걸며 행성을 파악한다.`,
        },
        type: "e",
      },
      {
        txt: {
          en: `I give them food on Earth and move to the next destination saying “thanks”.`,
          ko: `고맙다는 말과 함께 다음 행선지로 이동한다.`,
        },
        type: "i",
      },
    ],
  },
  {
    q: {
      en: `The food is better than you thought. What do you think?`,
      ko: `식량이 생각보다 너무 맛있다! 당신이 드는 생각은?`,
    },
    a: [
      {
        txt: {
          en: `How did they make this? I think it tastes similar to the hardtacks we ate on Earth.`,
          ko: `이건 어떻게 만든걸까, 지구에서 먹었던 빵이랑 비슷한 맛인 것 같은데`,
        },
        type: "t",
      },
      {
        txt: {
          en: `Wow, it's crispy on the outside and moist on the inside. Should I ask for more?`,
          ko: `와 진짜 겉은 바삭하고 안엔 촉촉하고 좀더 달라고 해볼까?`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `Now that you are full, should you leave now?`,
      ko: `배도 든든하게 채웠으니 이제 떠나볼까?`,
    },
    a: [
      {
        txt: {
          en: `I need strength to do outdoor activities, so I'll take a break.`,
          ko: `바깥활동을 할려면 힘이 있어야하니까 조금 쉬다 가야겠다.`,
        },
        type: "j",
      },
      {
        txt: {
          en: `After packing my luggage lightly, I start to leave for the next destination right away.`,
          ko: `빨리 다음 행선지로 이동해야하니까 대충 우겨넣고 출발한다.`,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `You are walking at a crossroads. Where should you go?`,
      ko: `걷다보니 갈림길이다. 어느곳으로 갈까?`,
    },
    a: [
      {
        txt: {
          en: `I go back to the way I came. We need to go on a stable path that was originally planned.`,
          ko: `왔던길로 간다. 원래 계획된 안정적인 길로 가야지!`,
        },
        type: "s",
      },
      {
        txt: {
          en: `Let's take a new path. We don't know what's going to happen!`,
          ko: `새로운 길로 가본다. 어떤일이 생길지 모르니까!`,
        },
        type: "n",
      },
    ],
  },
  {
    q: {
      en: `A villager passing by talks to you, saying, "Where are you from?”`,
      ko: `내 차림새가 수상했는지 지나가는 마을 주민이 말을 건다 “어디서 오셨어요?”`,
    },
    a: [
      {
        txt: {
          en: `“I just came by for a short trip”, pretending to know about the planet.`,
          ko: `“아 저는 그냥 잠깐 여행차 들린 것 입니다.”라고 행성에 대해 아는척을 한다.`,
        },
        type: "n",
      },
      {
        txt: {
          en: `I explain in detail about the situation I'm in and discuss how to act with him.`,
          ko: `자신이 처한 상황에 대해 구체적으로 설명하고 어떻게 행동해야할지 의논한다. `,
        },
        type: "s",
      },
    ],
  },
  {
    q: {
      en: `The villager says he wants to invite you to his house and hear more stories. What's your choice?`,
      ko: `자신의 집에 초대해서 이야기를 더 듣고 싶다는 주민, 당신의 선택은?`,
    },
    a: [
      {
        txt: {
          en: `I want to know more about the planet, so I readily accept his invitation.`,
          ko: `낯선사람이지만 행성에 대해 더 알고 싶으니 흔쾌히 수락한다.`,
        },
        type: "e",
      },
      {
        txt: {
          en: `“Sorry, I have a lot on my schedule. Could I have a rain check on that?”`,
          ko: `일정이 있다며 다음을 기약한다.`,
        },
        type: "i",
      },
    ],
  },
  {
    q: {
      en: `In the end, after persistent persuasion from the villagers, you end up at his place. What do you think about his house?`,
      ko: `결국 마을 주민의 끈질긴 설득 끝에 집에 도착했다. 당신의 생각은?`,
    },
    a: [
      {
        txt: {
          en: `‘The house is very spacious. It must be hard to clean.’`,
          ko: `'집이 굉장히 넓군. 청소하기 힘들겠어.'`,
        },
        type: "t",
      },
      {
        txt: {
          en: `‘The house is spacious and luxurious. I've never seen such a pretty and fancy house.’`,
          ko: `'집이 넓고 호화롭다. 이렇게 예쁘고 화려한 집은 처음이야.'`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `Earthman is going to wine and dine you. At that moment, there's a drink that has a similar scent on parent planet. What's your choice?`,
      ko: `당신에게 차를 대접하려 한다. 고향 행성에서 마시던 차를 발견했는데?`,
    },
    a: [
      {
        txt: {
          en: `I choose a familiar flavor.`,
          ko: `익숙한 맛을 고른다.`,
        },
        type: "s",
      },
      {
        txt: {
          en: `I try a new taste.`,
          ko: `새로운 행성의 차를 시도해본다.`,
        },
        type: "n",
      },
    ],
  },
  {
    q: {
      en: `You spilled the drink on the villager by accident. What would you say?`,
      ko: `실수로 당신이 마을 주민에게 차를 엎었다. 당신의 행동은?`,
    },
    a: [
      {
        txt: {
          en: `“I'm really sorry. I'll go to the pharmacy right away. Fast treatment is required for burns.”`,
          ko: `정말 죄송합니다. 약국에 다녀오겠습니다. 화상에는 빠른 처치가 필요합니다.`,
        },
        type: "t",
      },
      {
        txt: {
          en: `“Are you okay? Did you get hurt a lot? I'm sorry I was careless.”`,
          ko: `괜찮으세요? 많이 다치셨어요? 제가 부주의해서 죄송합니다.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `A villager, saying “I’m okay”, is heading to the bathroom. What are you going to do next?`,
      ko: `괜찮다며 화장실로 향하는 마을 주민, 당신의 행동은?`,
    },
    a: [
      {
        txt: {
          en: `Stomping my feet, I'm trying to seek a solution.`,
          ko: `발을 동동 구르며 이 상황을 타개할 방안을 찾는다.`,
        },
        type: "j",
      },
      {
        txt: {
          en: `I would go to the pharmacy right away and buy the ointment needed for burns.`,
          ko: `당장 약국에가서 화상에 필요한 연고를 사온다.`,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `After the situation, he would answer any questions about this planet. What are you going to ask?`,
      ko: `상황이 정리되고 이 행성에 대해 물어보고 싶은 것이 있으면 물어보라 하는데..`,
    },
    a: [
      {
        txt: {
          en: `“Is this planet like Earth? How do you make a living on this planet?”`,
          ko: `"이 행성은 지구라는 행성과 같은가요? 전반적인 생활이 어떻게 이루어지는지요."`,
        },
        type: "t",
      },
      {
        txt: {
          en: `“What made you buy this big house? The house is so pretty that I want to live here, too.”`,
          ko: `"이 집은 어떻게 해서 살게되신 건가요? 집이 너무 예뻐서 저도 살고싶어요."`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `Alien asks about what your next plan is. What would you say?`,
      ko: `앞으로의 계획을 묻는데, 당신의 대답은?`,
    },
    a: [
      {
        txt: {
          en: `“I'll take my chances. For now, I'm just going back to the flowery path that was on the way I came.” `,
          ko: `어떻게든 되겠죠! 일단 아까 풍경이 예쁜 곳으로 가보려구요.`,
        },
        type: "p",
      },
      {
        txt: {
          en: `I'm going to leave after I secure enough food.`,
          ko: `식량을 충분히 확보한 후 움직일 예정입니다.`,
        },
        type: "j",
      },
    ],
  },
  {
    q: {
      en: `Alien tells you he can introduce someone who will guide you. What would you say?`,
      ko: `안내해줄 사람을 소개시켜준다는데, 당신의 대답은?`,
    },
    a: [
      {
        txt: {
          en: `“No, thanks. I prefer to go alone.”`,
          ko: `괜찮습니다. 혼자가 편해서요.`,
        },
        type: "i",
      },
      {
        txt: {
          en: `“Thank you. the more the better.”`,
          ko: `감사합니다! 사람은 많을 수록 좋죠.`,
        },
        type: "e",
      },
    ],
  },
  {
    q: {
      en: `After coming out of the house, I notice that the ground was uneven. What would you do next?`,
      ko: `집에서 나온 후 찬찬히 행성에 대해 파악하려는데 지면이 울퉁불퉁하다.`,
    },
    a: [
      {
        txt: {
          en: `“Wow it looks like an volcanic cone in Halla Mt on Earth. I would look around to see if there's a warm place nearby.”`,
          ko: `주변에 따듯한 곳이 있나 둘러본다.`,
        },
        type: "s",
      },
      {
        txt: {
          en: `“The shape is really pretty. It looks like a chair and it's nice! I would sit down.”`,
          ko: `모양이 굉장히 예쁘네 의자 같고 좋다! 하며 앉는다.`,
        },
        type: "n",
      },
    ],
  },
  {
    q: {
      en: `You slipped on a bumpy road while walking? What would you do next?`,
      ko: `걷다가 울퉁불퉁한 곳에 걸려 미끄러진 당신, 다음 행동은?`,
    },
    a: [
      {
        txt: {
          en: `I would cry because the situation I'm in is so sad.`,
          ko: `아픈 곳을 어루만지며 처한 상황이 너무 슬퍼 엉엉 운다.`,
        },
        type: "f",
      },
      {
        txt: {
          en: `I'm sad but I'm putting on my thinking cap about how to move on with my painful legs.`,
          ko: `슬프지만 아픈 다리로 어떻게 나아가야할까 생각한다. `,
        },
        type: "t",
      },
    ],
  },
  {
    q: {
      en: `Sitting down and looking at the floor carefully, I think I found a clue to the out Earth. What is this book?`,
      ko: `앉아서 바닥을 찬찬히 보니 지구를 떠나는 단서를 발견한 것 같다. 이 책은 뭐지?`,
    },
    a: [
      {
        txt: {
          en: `Read the book carefully, carefully understand the planet, and make plans.`,
          ko: `책을 꼼꼼히 읽으며 이 행성에 대해 찬찬히 파악하고 계획을 세운다.`,
        },
        type: "j",
      },
      {
        txt: {
          en: `After looking at the title, I put it in my bag thinking that I will need it someday.`,
          ko: `제목을 보고 언젠간 필요하겠지 하며 가방에 넣는다. `,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `Sitting down and looking at around carefully, I found a book saying how to go back to the Earth. What is this book? What would you do next?`,
      ko: `나와 비슷한 상황에 처한 지구인이 있는 것 같다. 하지만 외계 생명체인지 확실하지 않은데?`,
    },
    a: [
      {
        txt: {
          en: `Reading carefully, I would figure out what this planet is and make plans for going back to the Earth.`,
          ko: `일단 말을 건다. 사람같아 보이니까!`,
        },
        type: "e",
      },
      {
        txt: {
          en: `After looking at the title, I put it in my bag, thinking that I will need it someday.`,
          ko: `경계하면서 지나친다. 사람이 아닐 수도 있는 형태이다.`,
        },
        type: "i",
      },
    ],
  },
  {
    q: {
      en: `A person who pretends to be an earthman talks to you.`,
      ko: `지구인의 행색을 한 사람이 말을건다.`,
    },
    a: [
      {
        txt: {
          en: `As far as I know, this planet is like this.`,
          ko: `제가 파악한 바로는 이 행성은 이렇습니다.`,
        },
        type: "t",
      },
      {
        txt: {
          en: `I think there are many similarities to my planet. Will I be able to escape as soon as soon as possible?`,
          ko: `고향 행성과 비슷한 점이 많은 것 같아요. 빠른 시일 내에 탈출할 수 있을지도?`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `Earthman encourages him to understand the planet faster than he does. What's your reaction?`,
      ko: `그가 자신보다 행성에 대해 빨리 파악했다며 고생했다고 격려한다. 당신의 반응은?`,
    },
    a: [
      {
        txt: {
          en: `It's so nice to know that I worked hard and worked hard.`,
          ko: `내가 열심히 노력하고 고생한걸 알아주니 너무 좋다.`,
        },
        type: "f",
      },
      {
        txt: {
          en: `It's true that I worked hard, but why don't they understand the parts that I organized so well?`,
          ko: `열심히 한 것도 맞는데 이렇게 잘 정리한 부분은 왜 안알아주지?`,
        },
        type: "t",
      },
    ],
  },
  {
    q: {
      en: `What do you want to say to Earthman who's struggling because he's in trouble?`,
      ko: `자신도 조난 당했다며 힘들어하는 그에게 해줄 말은?`,
    },
    a: [
      {
        txt: {
          en: `Shouldn't we decide what to do now?`,
          ko: `그럼 이제 어떻게 할건지 정해야 하지 않겠습니까?`,
        },
        type: "t",
      },
      {
        txt: {
          en: `It's really hard, right? I'm in the same situation. Let's come up with a plan together.`,
          ko: `많이 힘드시죠, 저도 같은 상황이예요. 같이 방안을 강구해봐요.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `It is said that Earthman failed to start explaining the spacecraft to escape the planet. What's your reaction?`,
      ko: `그가 이 행성을 탈출하기 위한 우주선에 대해 설명해주는데 출발에 실패했다고 한다 당신의 반응은?`,
    },
    a: [
      {
        txt: {
          en: `Where and how did you find the spaceship? How do I use it?`,
          ko: `어디서 어떻게 발견한 우주선이죠? 사용방법은요?`,
        },
        type: "t",
      },
      {
        txt: {
          en: `You must have had a hard time. You somehow ended up failing.`,
          ko: `많이 힘드셨겠어요. 어쩌다 실패하게 되셨어요.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `Earthman says Earthman is happy to meet a creature who thinks the same way, and asks to be a friend. What do you think?`,
      ko: `같은 생각을 하는 생명체를 만나서 기쁘다고 하는 그, 친구가 되자고 한다. 당신의 생각은?`,
    },
    a: [
      {
        txt: {
          en: `How much did we talk to each other to become friends?`,
          ko: `얼마나 얘기를 나눴다고 이렇게 바로 친구가 되자고 하지?`,
        },
        type: "i",
      },
      {
        txt: {
          en: `Good! We'll be good friends.`,
          ko: `좋아요 ! 우리는 좋은 친구가 될 수 있을 거예요. `,
        },
        type: "e",
      },
    ],
  },
  {
    q: {
      en: `What's your choice to suggest going back to see the spaceship you failed in?`,
      ko: `자신이 실패했던 우주선에 대해 다시 보러가자고 제안하는 그, 당신의 선택은?`,
    },
    a: [
      {
        txt: {
          en: `Let's go to find out the cause clearly where we failed.`,
          ko: `어느 부분에서 실패했는지 명확하게 원인을 파악하러 갑시다.`,
        },
        type: "j",
      },
      {
        txt: {
          en: `Yes, good. Isn't it a long way to go?`,
          ko: `네 좋아요. 가는길이 멀지는 않은가요?`,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `Both spaceships and books were equipped to escape. What will you do?`,
      ko: `탈출하기 위한 우주선과 책이 모두 구비되었다. 당신이 취할 행동은?`,
    },
    a: [
      {
        txt: {
          en: `I think about it a little more, make a specific plan, and start.`,
          ko: `조금 더 생각해보고 구체적인 계획을 세운 후 출발한다.`,
        },
        type: "j",
      },
      {
        txt: {
          en: `Let's turn it on. I'm going to turn it on.`,
          ko: `일단 가동시켜보자, 시동을 켠다.`,
        },
        type: "p",
      },
    ],
  },
  {
    q: {
      en: `The engine is on and ready to depart for "Our Planet". What's your choice?`,
      ko: `시동이 켜지고 집으로 출발할 준비가 되었다. 당신의 선택은?`,
    },
    a: [
      {
        txt: {
          en: `Check the engine for the last time and determine if there is enough battery.`,
          ko: `엔진을 마지막으로 확인하고 배터리가 넉넉한지 파악한다. `,
        },
        type: "t",
      },
      {
        txt: {
          en: `Let's go safely and arrive on "Our Planet". It's a bit scary but we can overcome it.`,
          ko: `우리 꼭 무사히 출발해서 집에 도착해요, 조금 무섭지만 극복할 수 있어요.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `The spacecraft appeared and navigation was set to "Our Planet". What do you think?`,
      ko: `우주선이 뜨고 네비게이션을 “우리 행성”으로 설정하였다. 당신의 생각은?`,
    },
    a: [
      {
        txt: {
          en: `Recent technology has advanced a lot these days.`,
          ko: `요즘 기술이 많이 발전했구나.`,
        },
        type: "t",
      },
      {
        txt: {
          en: `I want to go home as soon as possible. It was such a hard and painful journey.`,
          ko: `하루빨리 집에 가고 싶다. 너무 힘들고 괴로운 여정이었어.`,
        },
        type: "f",
      },
    ],
  },
  {
    q: {
      en: `You're starting to see the spaceship now! What do you think?`,
      ko: `이제 떠날 우주선이 보이기 시작한다! 당신의 생각은?`,
    },
    a: [
      {
        txt: {
          en: `I need to hurry up and organize this planet and let the world know.`,
          ko: `얼른 가서 이 행성에 대해 정리하고 세상에 알려야지`,
        },
        type: "n",
      },
      {
        txt: {
          en: `I want to go home, wash up, sleep, and have a delicious dinner.`,
          ko: `집가서 얼른 씻고 자고 맛있는 저녁 먹고싶다`,
        },
        type: "s",
      },
    ],
  },
  {
    q: {
      en: `After a long journey, it's time to say goodbye to Earthman. What will you do?`,
      ko: `긴 여정을 끝내고 지구인과 작별할 시간이 왔다. 당신이 취할 행동은? `,
    },
    a: [
      {
        txt: {
          en: `You says "thank you for driving the spaceship and heads home."`,
          ko: `우주선을 태워줘서 고맙다고 하고 집으로 향한다. `,
        },
        type: "i",
      },
      {
        txt: {
          en: `You hug Earthman and exchange contact information, saying he worked hard. Let's talk about the planet next time and praise Earthman as a comrade who has lived together in hardship and adversity.`,
          ko: `그와 수고했다며 포옹을 나누고 연락처를 주고받는다. 다음에도 행성에 대해 이야기해보자며 고난과 역경을 같이 지낸 전우라고 칭찬한다. `,
        },
        type: "e",
      },
    ],
  },
].sort(() => Math.random() - 0.5);

const Test = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [quizIndex, setQuizIndex] = useState(0);
  const [showInputField, setInputFieldVisibility] = useState(false);
  const [lang, setLang] = useState("en");
  const [user, setUser] = useState({
    name: "",
    birth: "",
    sex: "female",
    contact: "",
    email: "",
    blood: "A",
    howDoYoulive: "Indulge",
    life: "REPUTATION",
    god: "No",
    alien: "No",
    ghost: "No",
    religion: "Christianity",
    agreement: false,
    planet: "No",
    planetText: "",
    music: "No",
    musicText: "",
    happy: "No",
    happyText: "",
  });
  const warningText = {
    ko: {
      name: "이름을 입력해주세요.",
      birth: "생년월일을 입력해주세요.",
      contact: "연락처를 입력해주세요.",
      email: "이메일을 입력해주세요.",
      religion: "종교를 입력해주세요.",
      agreement: "개인정보 수집 동의에 체크해주세요.",
    },
    en: {
      name: "Please type your name.",
      birth: "Please enter your date of birth.",
      contact: "Please enter your contact information.",
      email: "Please enter your e-mail.",
      religion: "Please enter your religion.",
      agreement: "Please check your consent to collect personal information.",
    },
  };

  const chooseSex = (sex) => {
    setUser({
      ...user,
      sex: sex,
    });
  };
  const onChangeInput = (e) => {
    const name = e.target.name;
    setUser({
      ...user,
      [name]: e.target.value,
    });
  };
  const toggleAgreement = () => {
    setUser({
      ...user,
      agreement: !user.agreement,
    });
  };
  const goTest = () => {
    if (user.name === "") {
      window.alert(warningText[lang].name);
      return;
    }
    if (user.birth === "") {
      window.alert(warningText[lang].birth);
      return;
    }
    if (user.contact === "") {
      window.alert(warningText[lang].contact);
      return;
    }
    if (user.email === "") {
      window.alert(warningText[lang].email);
      return;
    }
    if (!user.agreement) {
      window.alert(warningText[lang].agreement);
      return;
    }
    firestore
      .collection("user")
      .add({
        ...user,
        createdAt: new Date(),
      })
      .then(() => {
        setInputFieldVisibility(false);
        setQuizIndex(quizIndex + 1);
      })
      .catch(() => {
        setInputFieldVisibility(false);
        setQuizIndex(quizIndex + 1);
      });
  };

  const [score, setScore] = useState({
    e: 0,
    i: 0,
    s: 0,
    n: 0,
    f: 0,
    t: 0,
    p: 0,
    j: 0,
  });

  const firstRows = [
    {
      name: "name",
      label: { ko: "이름", en: "Name" },
      type: "text",
      placeHolder: {
        ko: "이름을 입력해주세요.",
        en: "Please puy your name.",
      },
    },
    {
      name: "birth",
      label: { ko: "생년월일", en: "Birth" },
      type: "text",
      placeHolder: {
        ko: "예시: 19911231",
        en: "Ex: 19911231",
      },
    },
  ];

  const secondRows = [
    {
      name: "contact",
      label: { ko: "연락처", en: "Contact" },
      type: "contact",
      placeHolder: {
        ko: "예시: 전화번호 or 메신져아이디 or 카카오톡아이디",
        en: "Ex: facebook id or kakaotalk id or phone",
      },
    },
    {
      name: "email",
      label: { ko: "이메일", en: "Email" },
      type: "email",
      placeHolder: {
        ko: "example@email.com",
        en: "example@email.com",
      },
    },
  ];

  const thirdSelects = [
    {
      name: "religion",
      label: { ko: "종교", en: "Religion" },
      options: [
        {
          ko: "기독교",
          en: "Christianity",
        },
        {
          ko: "카톨릭",
          en: "Catholicism",
        },
        {
          ko: "불교",
          en: "Buddhism",
        },
        {
          ko: "흰두교",
          en: "Hinduism",
        },
        {
          ko: "기타",
          en: "others",
        },
      ],
    },
    {
      name: "region",
      label: { ko: "지역", en: "Region" },
      options: [
        {
          ko: "북아메리카",
          en: "North America",
        },
        {
          ko: "남아메리카",
          en: "South America",
        },
        {
          ko: "서유럽",
          en: "Western Europe",
        },
        {
          ko: "동유럽",
          en: "Eastern Europe",
        },
        {
          ko: "러시아",
          en: "Rusia",
        },
        {
          ko: "중동",
          en: "Middle East",
        },
        {
          ko: "아프리카",
          en: "Africa",
        },
        {
          ko: "중동",
          en: "Oceania",
        },
        {
          ko: "동북아시아",
          en: "Northeast Asia",
        },
        {
          ko: "동남아시아",
          en: "Southeast Asia",
        },
      ],
    },
    {
      name: "blood",
      label: { ko: "혈액형", en: "Blood" },
      options: [
        {
          ko: "A",
          en: "A",
        },
        {
          ko: "B",
          en: "B",
        },
        {
          ko: "AB",
          en: "AB",
        },
        {
          ko: "O",
          en: "O",
        },
        {
          ko: "others",
          en: "others",
        },
      ],
    },
    {
      name: "life",
      label: { ko: "삶에서 중요한 것은?", en: "What's important in life?" },
      options: [
        {
          ko: "명성",
          en: "REPUTATION",
        },
        {
          ko: "돈",
          en: "MONEY",
        },
        {
          ko: "관계",
          en: "RELATIONSHIP",
        },
        {
          ko: "일",
          en: "WORK",
        },
        {
          ko: "즐거움",
          en: "JOY",
        },
      ],
    },
    {
      name: "howDoYoulive",
      label: { ko: "어떻게 살고 있나요?", en: "How do you live?" },
      options: [
        {
          ko: "즐기는 삶",
          en: "Indulge",
        },
        {
          ko: "자기실현",
          en: "Fulfiling",
        },
        {
          ko: "중독된 삶",
          en: "Addicted",
        },
        {
          ko: "헌신",
          en: "Dedicated",
        },
        {
          ko: "생존",
          en: "Survive",
        },
      ],
    },
    {
      name: "god",
      label: { ko: "신을 믿나요?", en: "Do you believe God?" },
      options: [
        {
          ko: "예",
          en: "Yes",
        },
        {
          ko: "아니오",
          en: "No",
        },
      ],
    },
    {
      name: "alien",
      label: { ko: "외계인을 믿나요?", en: "Do you believe Alien?" },
      options: [
        {
          ko: "예",
          en: "Yes",
        },
        {
          ko: "아니오",
          en: "No",
        },
      ],
    },
    {
      name: "ghost",
      label: { ko: "유령을 믿나요?", en: "Do you believe Ghost?" },
      options: [
        {
          ko: "예",
          en: "Yes",
        },
        {
          ko: "아니오",
          en: "No",
        },
      ],
    },
  ];

  const getResult = () => {
    return `${score.e > score.i ? "e" : "i"}${score.s > score.n ? "s" : "n"}${
      score.f > score.t ? "f" : "t"
    }${score.p > score.j ? "p" : "j"}`;
  };

  const selectButton = useCallback((target) => {
    if (!target) {
      return;
    }
    setScore({
      ...score,
      [target.type]: score[target.type] + 1,
    });
    if (quizIndex === 11) {
      setInputFieldVisibility(true);
    } else {
      setQuizIndex(quizIndex + 1);
    }
  });

  useEffect(() => {
    const localLang = localStorage.getItem("cooldoc_lang");
    const selectedLang = localLang === "ko" ? "ko" : "en";
    setLang(selectedLang);
  }, [lang]);

  useEffect(() => {
    if (quiz.length <= quizIndex) {
      setLoading(true);
      setTimeout(() => {
        history.push(`/result/${getResult()}`);
      }, 3000);
    }
  }, [quizIndex]);

  return (
    <>
      {!loading ? (
        !showInputField ? (
          <div id="top-container" class="question">
            <div className="container test">
              <img className="logo" src="/image/logo.png" alt="logo" />
              <Progress index={quizIndex} maxIndex={quiz.length} />
              <img src="/image/doc_pointing.png" className="point-down" />
              <section
                className="quiz-container"
                style={{
                  width: `${quiz.length * 320}px`,
                  transform: `translate3d(-${quizIndex * 320}px, 0px, 0px)`,
                }}
              >
                {quiz.map((v, i) => {
                  return (
                    <div
                      className={`quiz-item ${i === quizIndex && "active"}`}
                      key={i}
                    >
                      <div className="q-title">
                        Q{i + 1}. {v.q[lang]}
                      </div>
                      <button
                        className="answer"
                        onClick={() => {
                          selectButton(i === quizIndex ? v.a[0] : null);
                        }}
                      >
                        {v.a[0].txt[lang]}
                      </button>
                      <button
                        className="answer"
                        onClick={() => {
                          selectButton(i === quizIndex ? v.a[1] : null);
                        }}
                      >
                        {v.a[1].txt[lang]}
                      </button>
                    </div>
                  );
                })}
              </section>
            </div>
          </div>
        ) : (
          <div id="top-container" class="reply">
            <div className="container reply">
              <h2 className="input-header">
                {lang === "en"
                  ? `THERE ARE MANY MORE QUESTIONS 
                  FOR MORE ACCURATE DIAGNOSIS. 
                  IF YOU WANT MORE PRECISE EVALUATION,
                  PLEASE GIVE US YOUR INFORMATION 
                  AND DO A FEW SURVEY.`
                  : `더 정확한 진단을 위한 질문이 많이 있다. 
                  정확한 진단을 원한다면 당신의 정보를 입력하라.`}
              </h2>
              <div className="row-container">
                {firstRows.map((v) => {
                  return (
                    <div className="row" key={v.name}>
                      <label>{v.label[lang]}</label>
                      <input
                        name={v.name}
                        onChange={onChangeInput}
                        type={v.type}
                        placeholder={v.placeHolder[lang]}
                      />
                    </div>
                  );
                })}
                <div className="row">
                  <label>{lang === "en" ? "Sex" : "성별"}</label>
                  <select onChange={(e) => chooseSex(e.target.value)}>
                    <option value="female">
                      {lang === "en" ? "female" : "여자"}
                    </option>
                    <option value="male">
                      {lang === "en" ? "male" : "남자"}
                    </option>
                    <option value="other">
                      {lang === "en" ? "other" : "기타"}
                    </option>
                  </select>
                </div>
                {secondRows.map((v) => {
                  return (
                    <div className="row" key={v.name}>
                      <label>{v.label[lang]}</label>
                      <input
                        name={v.name}
                        onChange={onChangeInput}
                        type={v.type}
                        placeholder={v.placeHolder[lang]}
                      />
                    </div>
                  );
                })}
                {thirdSelects.map((v) => {
                  return (
                    <div className="row" key={v.name}>
                      <label>{v.label[lang]}</label>
                      <select onChange={onChangeInput} name={v.name}>
                        {v.options.map((o) => {
                          return (
                            <option value={o.en} key={o.en}>
                              {lang === "en" ? o.en : o.ko}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                })}
                {/* 선택 및 서술형 질문 */}
                <div className="row">
                  <label>
                    {lang === "en"
                      ? "Do you care about our planet?"
                      : "당신은 우리의 행성에 신경 쓰고 있나요?"}
                  </label>
                  <select onChange={onChangeInput} name="planet">
                    <option value="Yes">{lang === "en" ? "Yes" : "예"}</option>
                    <option value="No">
                      {lang === "en" ? "No" : "아니요"}
                    </option>
                  </select>
                </div>
                {user.planet === "Yes" && (
                  <div className="row">
                    <label>
                      {lang === "en"
                        ? "IF YOU DO, TELL ME WHAT YOU DO."
                        : "하고 있다면 어떤걸 하는지 말해주세요."}
                    </label>
                    <input
                      name="planetText"
                      onChange={onChangeInput}
                      type="text"
                    />
                  </div>
                )}
                <div className="row">
                  <label>
                    {lang === "en"
                      ? "DO YOU LIKE MUSIC?"
                      : "음악을 좋아하나요?"}
                  </label>
                  <select onChange={onChangeInput} name="music">
                    <option value="Yes">{lang === "en" ? "Yes" : "예"}</option>
                    <option value="No">
                      {lang === "en" ? "No" : "아니요"}
                    </option>
                  </select>
                </div>
                {user.music === "Yes" && (
                  <div className="row">
                    <label>
                      {lang === "en"
                        ? "IF YOU DO, WHAT KIND?"
                        : "좋아한다면 어떤 종류인가요?"}
                    </label>
                    <input
                      name="musicText"
                      onChange={onChangeInput}
                      type="text"
                    />
                  </div>
                )}
                <div className="row">
                  <label>
                    {lang === "en" ? "ARE YOU HAPPY?" : "당신은 행복한가요?"}
                  </label>
                  <select onChange={onChangeInput} name="happy">
                    <option value="Yes">{lang === "en" ? "Yes" : "예"}</option>
                    <option value="No">
                      {lang === "en" ? "No" : "아니요"}
                    </option>
                  </select>
                </div>
                {user.happy === "No" && (
                  <div className="row">
                    <label>
                      {lang === "en"
                        ? "IF NOT, what make you happy?"
                        : "아니라면 당신의 행복은 무엇인가요?"}
                    </label>
                    <input
                      name="happyText"
                      onChange={onChangeInput}
                      type="text"
                    />
                  </div>
                )}
              </div>
              <div className="row agreement">
                <input
                  id="check-agreement"
                  type="checkbox"
                  onChange={toggleAgreement}
                />
                <label htmlFor="check-agreement" className="check-agreement">
                  {lang === "en"
                    ? "To save earth, I agree to participate and receive information."
                    : "지구지키기 프로젝트를 위해 정보수신에 동의합니다."}
                </label>
              </div>
              <button className="cta" onClick={goTest}>
                {lang === "en"
                  ? "For more precise evaluation click"
                  : "더 자세한 테스트하기"}
              </button>
            </div>
          </div>
        )
      ) : (
        <div id="top-container" class="question">
          <div id="loading-container">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="loading-text">
              {lang === "en" ? "Analyzing..." : "분석 중..."}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Test;

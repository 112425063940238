import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC-nDVGL0zedDL7v0PnfyA8Aqx-yh2999M",
  authDomain: "cooldoc-dc159.firebaseapp.com",
  projectId: "cooldoc-dc159",
  storageBucket: "cooldoc-dc159.appspot.com",
  messagingSenderId: "188141209026",
  appId: "1:188141209026:web:8b54b840be1c93648a825b",
  measurementId: "G-VXGD1XQBC5",
};

const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
const firestore = firebaseApp.firestore();

firebase.analytics();

export { firebase, firestore };
